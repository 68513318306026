<template>
    <div id="application">
        <router-view></router-view>
        <footer-navigation></footer-navigation>
    </div>
</template>

<script>
export default {
    name: 'Application',
    created: function(){

    }
}
</script>

<style scoped lang="less">
    article
    {
        min-height: 100vh;
    }
</style>
